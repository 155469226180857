<template>
  <Register v-if="this.$route.query.key == 'e2ca26ee-950f-481d-a9f7-1fba4d8eeab1'" />
  <Upgrade v-else-if="this.$route.query.key == '0de70575-5cdd-40b9-9a43-87adefc552d5'"  package_name="DFY"/>
  <Upgrade v-else-if="this.$route.query.key == 'e6ce88d7-5898-431a-bf43-8cfd60868a70'"  package_name="UNLIMITED"/>
  <Upgrade v-else-if="this.$route.query.key == '65f3dd59-1565-4fb8-b877-8979dfc52d54'"  package_name="PROFIT BOOSTER"/>
  <div v-else>
    {{ redirect() }}
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Register from '../../components/register.vue';
import Upgrade from '../../components/upgrade.vue';

export default {
  beforeCreate() {
    document.title = this.$route.meta.title + " | " + this.$sitename;
  },
  mounted() {
    this.$Progress.finish();
    let key = this.$route.query.key;
    if (key == "" || key == null) {
      this.$router.replace({ name: "login" });
    }
  },
  methods: {
    redirect(){
      this.$router.replace({ name: "login" });
    }
  },
  components: { Register, Upgrade }
}
</script>
