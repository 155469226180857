<template>
    <main class="justify-center mx-auto w-full md:w-3/4 lg:w-5/6">
        <div class="mx-1 my-4" v-if="isLoading">
            <content-placeholders :rounded="true" :centered="true">
                <content-placeholders-img />
                <content-placeholders-heading />
                <content-placeholders-heading />
            </content-placeholders>
        </div>
        <div v-else>
            <div class="card p-2 shadow-lg compact text-center bg-base-100 mx-1 mt-5 mb-3">
                <h2 class="font-bold text-lg text-white">
                    Message Stats
                </h2>
            </div>

            <div class="flex flex-col mt-8">
                <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                    <div
                        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                        <table class="min-w-full">
                            <thead>
                                <tr>
                                    <th
                                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-base-100">
                                        Subject</th>
                                    <th
                                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-base-100">
                                        Expected Opens</th>
                                    <th
                                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-base-100">
                                        Credit Per Opens</th>
                                    <th
                                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-base-100">
                                        Opens</th>
                                    <th
                                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-base-100">
                                        Status</th>
                                    <th
                                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-base-100">
                                        Publication Status</th>
                                    <th
                                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-base-100">
                                        Action</th>
                                </tr>
                            </thead>

                            <tbody class="bg-base-100">
                                <tr v-for="msg in messages" :key="msg.id">
                                    <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div class="text-sm leading-5 text-white">{{ msg.title }}</div>
                                    </td>

                                    <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div class="text-sm leading-5 text-white">{{ msg.expected_opens }}</div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div class="text-sm leading-5 text-white">{{ msg.credit_click }}</div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div class="text-sm leading-5 text-white">{{ msg.views }}</div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <span v-if="msg.status == 1"
                                            class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                                            Active</span>

                                        <span v-else
                                            class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-green-100 rounded-full">
                                            Disabled</span>

                                    </td>
                                    <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <span
                                            class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                                            Published</span>
                                    </td>

                                    <td
                                        class="px-6 py-4 text-sm leading-5 text-white whitespace-no-wrap border-b border-gray-200">

                                        <router-link :to="{ name: 'message-edit', params: { code: msg.code } }">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-blue-400"
                                                fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                            </svg>
                                        </router-link>

                                        <div @click="deleteButtonClick(msg.id, msg.title)">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-red-400"
                                                fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                            </svg>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <modal :if-close="true" :showing="deleteModal" @update:modal="deleteModal = $event">
            <div v-if="deleteModal" class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-1">
                <div class="divider">Are You Sure ?</div>
                <div class="text-2xl mt-5">
                    Delete : {{ deleteInfo.title }} !<span> </span>
                </div>
                <div class="form-control mt-8 mb-3">
                    <div class="flex flex-row space-x-5 justify-center">
                        <button :disabled="isDeleting" class="btn btn-outline" @click="deleteModal = false">
                            Cancel
                        </button>
                        <button :disabled="isDeleting" class="btn btn-error" @click="deleteMessage">
                            <svg-loading v-if="isDeleting" class="mr-1" height="16px" width="16px" />
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </modal>

    </main>
</template>

<script>
import axios from "axios";

export default {
    beforeCreate() {
        document.title = this.$route.meta.title + " | " + this.$sitename;
    },
    created() {
        this.getMessages();
    },
    mounted() {
        this.$Progress.finish();
    },
    data() {
        return {
            url: this.$config.FILE_URL,
            messages: [],
            errors: {},
            isLoading: false,
            isDeleting: false,
            deleteModal: false,
        };
    },
    methods: {
        getMessages() {
            axios.get('/my-message')
                .then(({ data }) => {
                    this.messages = data;
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log('Something went wrong')
                })
        },

        deleteButtonClick(id, title) {
            this.deleteInfo = { id: id, title: title };
            this.deleteModal = true;
        },
        deleteMessage() {
            this.isDeleting = true;
            this.errors = {};
            axios
                .post("/delete-message", { id: this.deleteInfo.id })
                .then(({ data }) => {
                    this.isDeleting = false;
                    this.getMessages();
                    this.deleteModal = false;
                    this.deleteInfo = {};
                    this.$noti("success", data.message);
                })
                .catch((error) => {
                    this.isDeleting = false;
                    if (error.response.status === 404 || error.response.status === 406 || error.response.status === 400) {
                        this.$noti("warning", error.response.data.message);
                    } else {
                        this.errors = error.response.data.errors;
                        this.$noti("error", "Something Went Wrong !");
                    }
                });
        },
    },
};
</script>
<style scoped>
</style>