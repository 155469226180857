<template>
  <main class="justify-center sm:mx-auto sm:w-full sm:max-w-md">
    <div class="card w-full shadow-2xl bg-base-200">
      <div class="flex flex-col space-y-2 justify-center items-center pt-3">
       <div class="card-title mb-0 text-center">
          <a href="/" class="text-2xl">
            <div class="shadow">
              <img :src="url + '/icon.png'" :alt="sitename" width="200">
            </div>
          </a>
        </div>
      </div>
      <div class="divider divide-base-300"></div>
      <div class="card-body pt-0">
        <p class="text-center text-primary-content">Upgrade to {{ package_name }} OTO</p>
        <form @submit.prevent="upgrade">
          <div class="form-control mt-5">
            <label class="label">Email :</label>
            <input type="email" required autocomplete="email" class="input input-accent" v-model="form.email">
            <!-- <input type="hidden" class="input input-accent" v-model="package_uuid"> -->
            <small class="text-red-600" v-if="errors.email">{{ errors.email[0] }}</small>
          </div>

          <div class="flex justify-center">
            <div class="form-control w-3/4 sm:w-2/3 mt-5">
              <button type="submit" class="btn btn-primary" :disabled="loading">
                <svg-loading height="16px" width="16px" class="mr-1" v-if="loading" />
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

  </main>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  beforeCreate() {
    document.title = this.$route.meta.title + ' | ' + this.$sitename;
  },
  data() {
    return {
      url: this.$config.FILE_URL,
      sitename: this.$sitename,
      form: {
        email: null,
        package_uuid: this.$route.query.key
      },
      errors: {},
      loading: false,
    }
  },
  mounted() {
    this.$Progress.finish();
  },
  props: ['package_name'],
  methods: {
    upgrade() {
      this.loading = true;
      this.errors = {};
      this.$axios.post('/upgrade', this.form)
        .then(() => {
          this.loading = false;
          this.$noti('success', 'Upgrade Successful, Login to Continue!');
          this.$router.replace({ name: 'login' });
        })
        .catch((error) => {
          this.loading = false;
          this.errors = error.response.data.errors
          this.$noti('error', 'Something Went Wrong !');
        })
    }
  }
}
</script>
