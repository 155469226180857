var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('div',{staticClass:"flex flex-col w-full shadow-xl bg-base-100 rounded-box text-center text-primary-content mt-3 px-2 py-2 md:px-4 md:py-4"},[_c('router-link',{staticClass:"navbar mb-2 shadow-lg bg-primary rounded-box px-4 md:px-6",attrs:{"to":"/change-password"}},[_c('div',{staticClass:"flex-none"},[_c('i',{staticClass:"bx bxs-lock text-lg"})]),_c('div',{staticClass:"flex-1 px-2 mx-2"},[_c('span',{staticClass:"text-lg font-bold"},[_vm._v(" Change Password ")])]),_c('div',{staticClass:"flex-none"},[_c('i',{staticClass:"bx bxs-chevron-right text-lg"})])]),_c('div',{staticClass:"navbar mb-2 shadow-lg bg-primary rounded-box px-4 md:px-6 clickable",on:{"click":function($event){return _vm.openModal(1)}}},[_vm._m(0),_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"navbar mb-2 shadow-lg bg-primary rounded-box px-4 md:px-6 clickable",on:{"click":function($event){return _vm.openModal(2)}}},[_vm._m(3),_vm._m(4),_vm._m(5)]),_c('div',{staticClass:"navbar mb-2 shadow-lg bg-primary rounded-box px-4 md:px-6 clickable",on:{"click":function($event){return _vm.openModal(3)}}},[_vm._m(6),_vm._m(7),_vm._m(8)])],1),_c('modal',{attrs:{"if-close":true,"showing":_vm.showModal},on:{"update:modal":function($event){_vm.showModal = $event}}},[(_vm.modalType === 1)?_c('div',[_c('theme-modal')],1):_vm._e(),(_vm.modalType === 2)?_c('div',[_c('language-modal')],1):_vm._e(),(_vm.modalType === 3)?_c('div',[_c('currency-modal')],1):_vm._e()])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-none"},[_c('i',{staticClass:"bx bxs-palette text-lg"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-1 px-2 mx-2"},[_c('span',{staticClass:"text-lg font-bold"},[_vm._v("App Theme ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-none"},[_c('i',{staticClass:"bx bxs-chevron-right text-lg"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-none"},[_c('i',{staticClass:"bx bx-globe text-lg"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-1 px-2 mx-2"},[_c('span',{staticClass:"text-lg font-bold"},[_vm._v("Choose Language ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-none"},[_c('i',{staticClass:"bx bxs-chevron-right text-lg"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-none"},[_c('i',{staticClass:"bx bx-money text-lg"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-1 px-2 mx-2"},[_c('span',{staticClass:"text-lg font-bold"},[_vm._v("Choose Currency ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-none"},[_c('i',{staticClass:"bx bxs-chevron-right text-lg"})])
}]

export { render, staticRenderFns }