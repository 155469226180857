var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"justify-center mx-auto w-full md:w-3/4 lg:w-5/6"},[(_vm.isLoading)?_c('div',{staticClass:"mx-1 my-4"},[_c('content-placeholders',{attrs:{"rounded":true,"centered":true}},[_c('content-placeholders-img'),_c('content-placeholders-heading'),_c('content-placeholders-heading')],1)],1):_c('div',[_vm._m(0),_c('div',{staticClass:"flex flex-col mt-8"},[_c('div',{staticClass:"py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"},[_c('div',{staticClass:"inline-block min-w-full overflow-hidden align-middle border-b border-base-100 shadow sm:rounded-lg"},[_c('table',{staticClass:"min-w-full"},[_vm._m(1),_c('tbody',{staticClass:"bg-base-100"},[_c('tr',[_c('td',{staticClass:"px-6 py-4 whitespace-no-wrap border-b border-base-100"},[_c('a',{attrs:{"href":"","target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" http://mobiclikzapp.com/join-with-"+_vm._s(_vm.user.username)+" ")])]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card p-2 shadow-lg compact text-center bg-base-100 mx-1 mt-5 mb-3"},[_c('h2',{staticClass:"font-bold text-lg text-white"},[_vm._v(" Invite Members ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-base-100 bg-base-100"},[_vm._v(" Share Link")]),_c('th',{staticClass:"px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-base-100 bg-base-100"}),_c('th',{staticClass:"px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-base-100 bg-base-100"}),_c('th',{staticClass:"px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-base-100 bg-base-100"}),_c('th',{staticClass:"px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-base-100 bg-base-100"}),_c('th',{staticClass:"px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-base-100 bg-base-100"},[_vm._v(" Share Button")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"px-6 py-4 whitespace-no-wrap border-b border-base-100"},[_c('div',{staticClass:"text-sm leading-5 text-white"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"px-6 py-4 whitespace-no-wrap border-b border-base-100"},[_c('div',{staticClass:"text-sm leading-5 text-white"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"px-6 py-4 whitespace-no-wrap border-b border-base-100"},[_c('div',{staticClass:"text-sm leading-5 text-white"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"px-6 py-4 whitespace-no-wrap border-b border-base-100"},[_c('div',{staticClass:"text-sm leading-5 text-white"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"px-6 py-4 text-sm leading-5 text-white whitespace-no-wrap border-b border-base-100"},[_c('div',{staticClass:"grid grid-cols-5 gap-1"},[_c('a',{attrs:{"href":"https://www.addtoany.com/share#url=http%3A%2F%2Fmobiclikzapp.com%2Fjoin-with-user&title=","target":"_blank"}},[_c('img',{staticStyle:{"background-color":"royalblue"},attrs:{"src":"https://static.addtoany.com/buttons/a2a.svg","width":"32","height":"32"}})]),_c('a',{attrs:{"href":"https://www.addtoany.com/add_to/facebook?linkurl=http%3A%2F%2Fmobiclikzapp.com%2Fjoin-with-user&linkname=","target":"_blank"}},[_c('img',{staticStyle:{"background-color":"royalblue"},attrs:{"src":"https://static.addtoany.com/buttons/facebook.svg","width":"32","height":"32"}})]),_c('a',{attrs:{"href":"https://www.addtoany.com/add_to/twitter?linkurl=http%3A%2F%2Fmobiclikzapp.com%2Fjoin-with-user&linkname=","target":"_blank"}},[_c('img',{staticStyle:{"background-color":"royalblue"},attrs:{"src":"https://static.addtoany.com/buttons/twitter.svg","width":"32","height":"32"}})]),_c('a',{attrs:{"href":"https://www.addtoany.com/add_to/email?linkurl=http%3A%2F%2Fmobiclikzapp.com%2Fjoin-with-user&linkname=","target":"_blank"}},[_c('img',{staticStyle:{"background-color":"royalblue"},attrs:{"src":"https://static.addtoany.com/buttons/email.svg","width":"32","height":"32"}})]),_c('a',{attrs:{"href":"https://www.addtoany.com/add_to/linkedin?linkurl=http%3A%2F%2Fmobiclikzapp.com%2Fjoin-with-user&linkname=","target":"_blank"}},[_c('img',{staticStyle:{"background-color":"royalblue"},attrs:{"src":"https://static.addtoany.com/buttons/linkedin.svg","width":"32","height":"32"}})])])])
}]

export { render, staticRenderFns }