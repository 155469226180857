<template>

  <main class="justify-center sm:mx-auto sm:w-full sm:max-w-md">

    <div class="card w-full shadow-2xl bg-base-200">
      <div class="flex flex-col space-y-2 justify-center items-center pt-3">
        <div class="card-title mb-0 text-center">
          <a href="/" class="text-2xl">
            <div class="shadow">
              <img :src="url + '/icon.png'" :alt="sitename" width="200">
            </div>
          </a>
        </div>
      </div>
      <div class="divider divide-base-300"></div>
      <div class="card-body pt-0">
        <p class="text-center text-primary-content" v-html="infoText" v-if="completed"></p>
        <p class="text-center text-primary-content" v-html="infoText" v-else></p>

        <div v-if="!completed">

          <div class="form-control mt-5" v-if="!mailSent">
            <input type="email" placeholder="Your Email Id" class="input input-accent" v-model="form.email">
            <small class="text-red-600" v-if="errors.email">{{ errors.email[0] }}</small>
          </div>

          <div class="form-control mt-5" v-if="mailSent">
            <input type="password" placeholder="Enter New Password" class="input input-accent" v-model.number="form.new_pass">
            <small class="text-red-600" v-if="errors.new_pass">{{ errors.new_pass[0] }}</small>
          </div>

          <div class="form-control mt-6" v-if="!mailSent">
            <button type="submit" class="btn btn-primary" :disabled="loading" @click.prevent="sendCode">
              <svg-loading height="16px" width="16px" class="mr-1" v-if="loading"/>
              Submit
            </button>
          </div>
          <div class="form-control mt-6" v-if="mailSent">
            <button type="submit" class="btn btn-primary" :disabled="resetLoading" @click.prevent="resetPassword">
              <svg-loading height="16px" width="16px" class="mr-1" v-if="resetLoading"/>
              Reset Password
            </button>
          </div>

        </div>
        <div class="divider divide-base-300 text-base-100">OR</div>
        <div class="form-control">
          <div>
            <router-link class="btn w-full btn-secondary" to="/login">← Sign In</router-link>
          </div>
        </div>
      </div>

    </div>

  </main>

</template>

<script>
export default {
  beforeCreate() {
    document.title = this.$route.meta.title + ' | ' + this.$sitename;
  },
  data() {
    return {
      url: this.$config.FILE_URL,
      sitename: this.$sitename,
      form: {
        email: null,
        new_pass: null,
      },
      errors: {},
      loading: false,
      resetLoading: false,
      mailSent: false,
      completed: false,
      infoText: 'Enter your email Id',
    }
  },
  mounted() {
    this.$Progress.finish();
  },
  methods: {
    sendCode() {
      this.loading = true;
      let email = this.form.email;
      this.errors = {};
      this.$axios.post('/forget', {email: email})
          .then(({data}) => {
            this.loading = false;
            this.mailSent = true;
            localStorage.setItem('email', email);
            this.infoText = data.message;
            //this.$noti('success', 'Code has been sent', 3000);
          })
          .catch((error) => {
            this.loading = false;
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.$noti('error', 'Something Went Wrong !', 3000);
            } else {
              let err = error.response.data.error;
              this.$noti('error', '' + err + '', 3000);
            }
          })
    },
    resetPassword() {
      this.resetLoading = true;
      let email = localStorage.getItem('email');
      this.errors = {};
      this.$axios.post('/reset-password', {email: email, new_pass: this.form.new_pass})
          .then(({data}) => {
            this.resetLoading = false;
            this.infoText = data.message;
            this.$noti('success', 'Password Reset Successful', 3000);
            localStorage.removeItem('email')
            this.completed = true;
          })
          .catch((error) => {
            this.resetLoading = false;
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.$noti('error', 'Something Went Wrong !', 3000);
            } else {
              let err = error.response.data.error;
              this.$noti('error', '' + err + '', 3000);
            }
          })
    },
  }

}
</script>
