import { render, staticRenderFns } from "./feed.vue?vue&type=template&id=53c4d59d&scoped=true&"
import script from "./feed.vue?vue&type=script&lang=js&"
export * from "./feed.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53c4d59d",
  null
  
)

export default component.exports