<template>
    <main class="justify-center mx-auto w-full md:w-3/4 lg:w-5/6">
        <div class="mx-1 my-4" v-if="isLoading">
            <content-placeholders :rounded="true" :centered="true">
                <content-placeholders-img />
                <content-placeholders-heading />
                <content-placeholders-heading />
            </content-placeholders>
        </div>
        <div v-else>
            <div class="card p-2 shadow-lg compact text-center bg-base-100 mx-1 mt-5 mb-3">
                <h2 class="font-bold text-lg text-white">
                    Invite Members
                </h2>
            </div>

            <div class="flex flex-col mt-8">
                <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                    <div
                        class="inline-block min-w-full overflow-hidden align-middle border-b border-base-100 shadow sm:rounded-lg">
                        <table class="min-w-full">
                            <thead>
                                <tr>
                                    <th
                                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-base-100 bg-base-100">
                                        Share Link</th>
                                    <th
                                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-base-100 bg-base-100">
                                    </th>
                                    <th
                                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-base-100 bg-base-100">
                                    </th>
                                    <th
                                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-base-100 bg-base-100">
                                    </th>
                                    <th
                                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-base-100 bg-base-100">
                                    </th>
                                    
                                    <th
                                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-base-100 bg-base-100">
                                        Share Button</th>
                                </tr>
                            </thead>

                            <tbody class="bg-base-100">
                                <tr>
                                    <td class="px-6 py-4 whitespace-no-wrap border-b border-base-100">
                                        <a href="" target="_blank" rel="noopener noreferrer">
                                            http://mobiclikzapp.com/join-with-{{ user.username }}
                                        </a>
                                    </td>

                                    
                                    <td class="px-6 py-4 whitespace-no-wrap border-b border-base-100">
                                        <div class="text-sm leading-5 text-white"></div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-no-wrap border-b border-base-100">
                                        <div class="text-sm leading-5 text-white"></div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-no-wrap border-b border-base-100">
                                        <div class="text-sm leading-5 text-white"></div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-no-wrap border-b border-base-100">
                                        <div class="text-sm leading-5 text-white"></div>
                                    </td>

                                    <td class="px-6 py-4 text-sm leading-5 text-white whitespace-no-wrap border-b border-base-100">
                                        <div class="grid grid-cols-5 gap-1">
                                            <a href="https://www.addtoany.com/share#url=http%3A%2F%2Fmobiclikzapp.com%2Fjoin-with-user&amp;title="
                                                target="_blank"><img src="https://static.addtoany.com/buttons/a2a.svg"
                                                    width="32" height="32" style="background-color:royalblue"></a>
                                            <a href="https://www.addtoany.com/add_to/facebook?linkurl=http%3A%2F%2Fmobiclikzapp.com%2Fjoin-with-user&amp;linkname="
                                                target="_blank"><img
                                                    src="https://static.addtoany.com/buttons/facebook.svg" width="32"
                                                    height="32" style="background-color:royalblue"></a>
                                            <a href="https://www.addtoany.com/add_to/twitter?linkurl=http%3A%2F%2Fmobiclikzapp.com%2Fjoin-with-user&amp;linkname="
                                                target="_blank"><img
                                                    src="https://static.addtoany.com/buttons/twitter.svg" width="32"
                                                    height="32" style="background-color:royalblue"></a>
                                            <a href="https://www.addtoany.com/add_to/email?linkurl=http%3A%2F%2Fmobiclikzapp.com%2Fjoin-with-user&amp;linkname="
                                                target="_blank"><img src="https://static.addtoany.com/buttons/email.svg"
                                                    width="32" height="32" style="background-color:royalblue"></a>
                                            <a href="https://www.addtoany.com/add_to/linkedin?linkurl=http%3A%2F%2Fmobiclikzapp.com%2Fjoin-with-user&amp;linkname="
                                                target="_blank"><img
                                                    src="https://static.addtoany.com/buttons/linkedin.svg" width="32"
                                                    height="32" style="background-color:royalblue"></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </main>
</template>

<script async src="https://static.addtoany.com/menu/page.js"></script>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
    beforeCreate() {
        document.title = this.$route.meta.title + " | " + this.$sitename;
    },
    created() {
    },
    computed: {
        ...mapGetters({
            user: "userInfo",
        }),
        url() {
            return this.$config.FILE_URL;
        },
        siteName() {
            return this.$sitename;
        },
    },
    mounted() {
        this.$Progress.finish();
    },
    data() {
        return {
            errors: {},
            isLoading: false,
        };
    },
    methods: {

    },
};
</script>
<style scoped>
</style>