<template>
  <main class="justify-center mx-auto mt-3 mb-4 sm:w-4/5 md:w-3/4 lg:w-4/6 overflow-hidden">

    <div class="pt-6 w-full shadow-2xl">

      <div class="card-body pt-0">

        <div class="form-control mt-2">
          <label class="label">
            <span class="label-text font-bold">Create Message Campaign or Select Email Template :
            </span>
          </label>

          <select class="select select-accent" v-model="msg_type">
            <option disabled="disabled" selected="selected" value="Select Option">
              Select Option
            </option>
            <option value="Message Campaign">Create Message Campaign From Youtube</option>
            <option value="Email Template">Select Email Template</option>
          </select>
        </div>

        <form @submit.prevent="uploadYoutube" v-if="msg_type == 'Message Campaign'">

          <div class="form-control mt-5">
            <label class="label">
              <span class="label-text font-bold">Youtube URL</span>
            </label>
            <input type="text" placeholder="Youtube Video URL..." class="input input-accent" v-model="form.url">
            <small class="text-red-600 ml-2" v-if="errors.url">{{ errors.url[0] }}</small>
          </div>

          <div>
            <div class="form-control mt-2">
              <label class="label">
                <span class="label-text font-bold">Thumbnail <small>(optional)</small> :</span>
              </label>
              <div class="mx-2">
                <input type="file" ref="select" accept=".jpg,.png,.jpeg" class="file" @change="imageChange">
                <small class="text-red-600 ml-2" v-if="errors.image">{{ errors.image[0] }}</small>
              </div>
            </div>

            <div class="form-control mt-5" v-if="form.image">
              <label class="label">
                <span class="label-text font-bold">Preview :</span>
              </label>
              <div class="flex flex-wrap justify-center">
                <div class="w-8/12 sm:w-6/12 px-4">
                  <img :src="form.image" alt="..." class="rounded max-w-full h-auto align-middle border-none" />
                </div>
              </div>
            </div>
          </div>

          <div class="form-control mt-5">
            <label class="label">
              <span class="label-text font-bold">Subject</span>
            </label>
            <input type="text" placeholder="Write a title..." class="input input-accent" v-model="form.title">
            <small class="text-red-600 ml-2" v-if="errors.title">{{ errors.title[0] }}</small>
          </div>
          <div class="form-control mt-3">
            <label class="label">
              <span class="label-text font-bold">Body :</span>
            </label>
            <!-- <textarea class="textarea h-24 textarea-bordered textarea-accent"
              placeholder="Write a description about the status" v-model="form.description"></textarea> -->
            <ckeditor v-model="form.description" tag-name="textarea"></ckeditor>

            <small class="text-red-600 ml-2" v-if="errors.description">{{ errors.description[0] }}</small>
          </div>

          <div class="form-control mt-5">
            <label class="label">
              <span class="label-text font-bold">Label :</span>
            </label>
            <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
              <!--            <div class="flex flex-no-wrap overflow-x-scroll scrolling-touch items-start gap-4">-->
              <div class="truncate" v-for="category in categories" :key="category.id">
                <label class="inline-flex items-center">
                  <input type="checkbox" checked="checked" class="checkbox checkbox-accent" :value="category.id"
                    v-model="form.categories">
                  <span class="label-text ml-1 md:ml-2">{{ category.name }}</span>
                </label>
              </div>
            </div>
            <small class="text-red-600 ml-2" v-if="errors.categories">{{ errors.categories[0] }}</small>
          </div>

          <div class="form-control mt-5">
            <label class="label">
              <span class="label-text font-bold">Languages :</span>
            </label>
            <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
              <div class="truncate" v-for="language in languages" :key="language.id">
                <label class="inline-flex items-center">
                  <input type="checkbox" checked="checked" class="checkbox checkbox-accent" :value="language.id"
                    v-model="form.languages">
                  <span class="label-text ml-1 md:ml-2">{{ language.name }}</span>
                </label>
              </div>
            </div>
            <small class="text-red-600 ml-2" v-if="errors.languages">{{ errors.languages[0] }}</small>
          </div>

          <div class="form-control mt-5">
            <label class="label">
              <span class="label-text font-bold">Expected Opens : </span>
            </label>
            <input type="number" placeholder="" class="input input-accent" v-model="form.expected_opens">
            <small class="text-red-600" v-if="errors.expected_opens">{{ errors.expected_opens[0] }}</small>
          </div>

          <div class="form-control mt-5">
            <label class="label">
              <span class="label-text font-bold">Credit Per Opens (Minimum required 1) : </span>
            </label>
            <input type="number" placeholder="" class="input input-accent" v-model="form.credit_click">
            <small class="text-red-600" v-if="errors.credit_click">{{ errors.credit_click[0] }}</small>
          </div>

          <div class="form-control mt-5">
            <label class="label">
              <span class="label-text font-bold">Publication Status : </span>
            </label>
            <select class="select select-accent" v-model="form.public_status">
              <option value="select one" disabled selected="selected">Select One</option>
              <option value="send">Send</option>
              <option value="default">Save</option>
            </select>
            <small class="text-red-600" v-if="errors.public_status">{{ errors.public_status[0] }}</small>
          </div>

          <div class="mt-4 p-6" v-if="uploading">
            <progress class="progress progress-accent" :value="uploadPercentage" max="100"></progress>
          </div>

          <div class="form-control mt-8 mb-3" :class="uploading ? 'disabledArea' : ''">
            <button type="submit" class="btn btn-primary">
              <svg-loading height="16px" width="16px" class="mr-1" v-if="uploading" />
              Upload
            </button>
          </div>
        </form>

        <form @submit.prevent="uploadYoutube" v-if="msg_type == 'Email Template'">

          <div class="form-control mt-5">
            <label class="label">
              <span class="label-text font-bold">Select Email Template : </span>
            </label>
            <select v-model="selectedId" class="select select-accent" @change="selectedTemplate">
              <option disabled="disabled" selected="selected" value="Select Email Template">
                Select Email Template
              </option>
              <option v-for="msg in messages" :value="msg.id" :key="msg.id">
                {{ msg.title }}
              </option>
            </select>
          </div>

          <div v-if="selectedId === selectedMessage.id">

            <div class="form-control mt-5">
              <label class="label">
                <span class="label-text font-bold">Youtube URL</span>
              </label>
              <input type="text" placeholder="Youtube Video URL..." class="input input-accent" v-model="form.url">
              <small class="text-red-600 ml-2" v-if="errors.url">{{ errors.url[0] }}</small>
            </div>

            <div>
              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Thumbnail <small>(optional)</small> :</span>
                </label>
                <div class="mx-2">
                  <input type="file" ref="select" accept=".jpg,.png,.jpeg" class="file" @change="imageChange">
                  <small class="text-red-600 ml-2" v-if="errors.image">{{ errors.image[0] }}</small>
                </div>
              </div>

              <div class="form-control mt-5" v-if="form.image">
                <label class="label">
                  <span class="label-text font-bold">Preview :</span>
                </label>
                <div class="flex flex-wrap justify-center">
                  <div class="w-8/12 sm:w-6/12 px-4">
                    <img :src="form.image" alt="..." class="rounded max-w-full h-auto align-middle border-none" />
                  </div>
                </div>
              </div>
            </div>

            <div class="form-control mt-5">
              <label class="label">
                <span class="label-text font-bold">Subject</span>
              </label>
              <input type="text" placeholder="Write a title..." class="input input-accent" v-model="form.title">
              <small class="text-red-600 ml-2" v-if="errors.title">{{ errors.title[0] }}</small>
            </div>
            <div class="form-control mt-3">
              <label class="label">
                <span class="label-text font-bold">Body :</span>
              </label>
              <!-- <textarea class="textarea h-24 textarea-bordered textarea-accent"
              placeholder="Write a description about the status" v-model="form.description"></textarea> -->
              <ckeditor v-model="form.description" tag-name="textarea"></ckeditor>

              <small class="text-red-600 ml-2" v-if="errors.description">{{ errors.description[0] }}</small>
            </div>

            <div class="form-control mt-5">
              <label class="label">
                <span class="label-text font-bold">Label :</span>
              </label>
              <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
                <!--            <div class="flex flex-no-wrap overflow-x-scroll scrolling-touch items-start gap-4">-->
                <div class="truncate" v-for="category in categories" :key="category.id">
                  <label class="inline-flex items-center">
                    <input type="checkbox" checked="checked" class="checkbox checkbox-accent" :value="category.id"
                      v-model="form.categories">
                    <span class="label-text ml-1 md:ml-2">{{ category.name }}</span>
                  </label>
                </div>
              </div>
              <small class="text-red-600 ml-2" v-if="errors.categories">{{ errors.categories[0] }}</small>
            </div>

            <div class="form-control mt-5">
              <label class="label">
                <span class="label-text font-bold">Languages :</span>
              </label>
              <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
                <div class="truncate" v-for="language in languages" :key="language.id">
                  <label class="inline-flex items-center">
                    <input type="checkbox" checked="checked" class="checkbox checkbox-accent" :value="language.id"
                      v-model="form.languages">
                    <span class="label-text ml-1 md:ml-2">{{ language.name }}</span>
                  </label>
                </div>
              </div>
              <small class="text-red-600 ml-2" v-if="errors.languages">{{ errors.languages[0] }}</small>
            </div>

            <div class="form-control mt-5">
              <label class="label">
                <span class="label-text font-bold">Expected Opens : </span>
              </label>
              <input type="number" placeholder="" class="input input-accent" v-model="form.expected_opens">
              <small class="text-red-600" v-if="errors.expected_opens">{{ errors.expected_opens[0] }}</small>
            </div>

            <div class="form-control mt-5">
              <label class="label">
                <span class="label-text font-bold">Credit Per Opens (Minimum required 1) : </span>
              </label>
              <input type="number" placeholder="" class="input input-accent" v-model="form.credit_click">
              <small class="text-red-600" v-if="errors.credit_click">{{ errors.credit_click[0] }}</small>
            </div>

            <div class="form-control mt-5">
              <label class="label">
                <span class="label-text font-bold">Publication Status : </span>
              </label>
              <select class="select select-accent" v-model="form.public_status">
                <option value="select one" disabled selected="selected">Select One</option>
                <option value="send">Send</option>
                <option value="default">Save</option>
              </select>
              <small class="text-red-600" v-if="errors.public_status">{{ errors.public_status[0] }}</small>
            </div>
          </div>

          <div class="mt-4 p-6" v-if="uploading">
            <progress class="progress progress-accent" :value="uploadPercentage" max="100"></progress>
          </div>

          <div class="form-control mt-8 mb-3" :class="uploading ? 'disabledArea' : ''">
            <button type="submit" class="btn btn-primary">
              <svg-loading height="16px" width="16px" class="mr-1" v-if="uploading" />
              Upload
            </button>
          </div>
        </form>

      </div>

    </div>

  </main>
</template>

<script>
import axios from "axios";

export default {
  beforeCreate() {
    document.title = this.$route.meta.title + ' | ' + this.$sitename;
  },
  created() {
    this.getUploadInfo();
    this.getMessages();
  },
  mounted() {
    this.$Progress.finish();
  },
  data() {
    return {
      msg_type: "Select Option",
      selectedId: "Select Email Template",
      form: {
        url: null,
        title: null,
        description: null,
        image: null,
        categories: [],
        languages: [],
        expected_opens: null,
        credit_click: null,
        public_status: "select one",
      },
      selectedMessage: {
        url: null,
        title: null,
        description: null,
        image: null,
        categories: [],
        languages: [],
        expected_opens: null,
        credit_click: null,
        public_status: "select one",
      },
      uploading: false,
      uploadPercentage: 0,
      errors: {},
      limit_left: null,
      categories: [],
      languages: [],
      messages: [],
    }
  },
  methods: {
    getMessages() {
      axios.get('/dfy-template-youtube')
        .then(({ data }) => {
          this.messages = data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log('Something went wrong')
        })
    },
    getUploadInfo() {
      axios.get('/upload-info')
        .then(({ data }) => {
          this.limit_left = data.limit_left;
          this.categories = data.categories;
          this.languages = data.languages;
        })
        .catch((error) => {
          console.log('Something went wrong')
        })
    },
    uploadYoutube() {
      this.uploading = true;
      axios.post('/upload-youtube', this.form, {
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
        }.bind(this)
      })
        .then(({ data }) => {
          this.uploading = false;
          this.uploadPercentage = 0;
          this.form = { url: null, title: null, description: null, image: null, categories: [], languages: [], expected_opens: null, credit_click: null, public_status: "select one", };
          this.getUploadInfo();
          this.$refs.select.value = null;
          this.$noti('success', '' + data.message + '')
        })
        .catch((error) => {
          this.uploading = false;
          this.uploadPercentage = 0;
          this.errors = error.response.data.errors;
          this.$noti('error', 'Something Went Wrong')
        })
    },
    imageChange(event) {
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.onload = event => {
        this.form.image = event.target.result
      };
      reader.readAsDataURL(file);
    },
    selectedTemplate() {
      let templateId = this.selectedId
      let messages = this.messages;

      const templateExist = messages.find(msg => msg.id === templateId);

      if (templateExist) {
        this.selectedMessage.id = templateExist.id,
          this.selectedMessage.title = templateExist.title;
        this.selectedMessage.description = templateExist.description;
        this.selectedMessage.image = "https://api.mobiclikzapp.com/storage/" + templateExist.thumbnail;
        //this.selectedMessage.url = templateExist.media.url;
        // this.selectedMessage.languages = templateExist.languages;
        this.selectedMessage.expected_opens = templateExist.expected_opens;
        this.selectedMessage.credit_click = templateExist.credit_click;
        this.selectedMessage.public_status = templateExist.public_status;
      } else {
        console.log("Something went wrong");
      }
    },
  },
}
</script>

<style scoped>
</style>