<template>
  <Register v-if="this.$route.query.key == '63182da4-f08d-4f4a-a333-2831ed636989'" />
  <Upgrade v-else-if="this.$route.query.key == 'b3e97e8f-1bb7-43cc-b98b-161a963b4384'"  package_name="DFY"/>
  <Upgrade v-else-if="this.$route.query.key == 'eb12bb95-ff75-43d4-89cd-aedd112fe045'"  package_name="UNLIMITED"/>
  <Upgrade v-else-if="this.$route.query.key == 'e936b77c-23f7-4977-a6ae-4f4a78ea49c9'"  package_name="PROFIT BOOSTER"/>
  <div v-else>
    {{ redirect() }}
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Register from '../../components/register.vue';
import Upgrade from '../../components/upgrade.vue';

export default {
  beforeCreate() {
    document.title = this.$route.meta.title + " | " + this.$sitename;
  },
  mounted() {
    this.$Progress.finish();
    let key = this.$route.query.key;
    if (key == "" || key == null) {
      this.$router.replace({ name: "login" });
    }
  },
  methods: {
    redirect(){
      this.$router.replace({ name: "login" });
    }
  },
  components: { Register, Upgrade }
}
</script>
