<template>
    <main class="justify-center mx-auto w-full md:w-3/4 lg:w-5/6">
        <div class="mx-1 my-4" v-if="isLoading">
            <content-placeholders :rounded="true" :centered="true">
                <content-placeholders-img />
                <content-placeholders-heading />
                <content-placeholders-heading />
            </content-placeholders>
        </div>
        <div v-else>
            <div class="card p-2 shadow-lg compact text-center bg-base-100 mx-1 mt-5 mb-3">
                <h2 class="font-bold text-lg text-white">
                    Message Template
                </h2>
            </div>

            <div class="flex flex-col mt-8">
                <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                    <div
                        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                        <table class="min-w-full">
                            <thead>
                                <tr>
                                    <th
                                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-base-100">
                                        Subject</th>
                                    <th
                                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-base-100">
                                        Expected Opens</th>
                                    <th
                                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-base-100">
                                        Credit Per Opens</th>
                                    <th
                                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-base-100">
                                        Action</th>
                                </tr>
                            </thead>

                            <tbody class="bg-base-100">
                                <tr v-for="msg in messages" :key="msg.id">
                                    <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div class="text-sm leading-5 text-white">{{ msg.title }}</div>
                                    </td>

                                    <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div class="text-sm leading-5 text-white">{{ msg.expected_opens }}</div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                        <div class="text-sm leading-5 text-white">{{ msg.credit_click }}</div>
                                    </td>

                                    <td
                                        class="px-6 py-4 text-sm leading-5 text-white whitespace-no-wrap border-b border-gray-200">

                                        <router-link :to="{ name: 'upload-image', params: { code: msg.code } }"
                                            class="btn btn-info btn-sm">
                                            <i class='bx bx-desktop'></i>
                                            View
                                        </router-link>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <modal :if-close="true" :showing="deleteModal" @update:modal="deleteModal = $event">
            <div v-if="deleteModal" class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-1">
                <div class="divider">Are You Sure ?</div>
                <div class="text-2xl mt-5">
                    Delete : {{ deleteInfo.title }} !<span> </span>
                </div>
                <div class="form-control mt-8 mb-3">
                    <div class="flex flex-row space-x-5 justify-center">
                        <button :disabled="isDeleting" class="btn btn-outline" @click="deleteModal = false">
                            Cancel
                        </button>
                        <button :disabled="isDeleting" class="btn btn-error" @click="deleteMessage">
                            <svg-loading v-if="isDeleting" class="mr-1" height="16px" width="16px" />
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </modal>

    </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
    beforeCreate() {
        document.title = this.$route.meta.title + " | " + this.$sitename;
    },
    created() {
        this.getMessages();
    },
    mounted() {
        this.$Progress.finish();
    },
    computed: {
        ...mapGetters({
            user: "userInfo",
        }),
    },
    data() {
        return {
            url: this.$config.FILE_URL,
            messages: [],
            errors: {},
            isLoading: false,
            isDeleting: false,
            deleteModal: false,
        };
    },
    methods: {
        getMessages() {
            let code = this.user.id;
            axios.get('/dfy-template')
                .then(({ data }) => {
                    this.messages = data;
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log('Something went wrong')
                })
        },

        deleteButtonClick(id, title) {
            this.deleteInfo = { id: id, title: title };
            this.deleteModal = true;
        },
        deleteMessage() {
            this.isDeleting = true;
            this.errors = {};
            axios
                .post("/delete-message", { id: this.deleteInfo.id })
                .then(({ data }) => {
                    this.isDeleting = false;
                    this.getMessages();
                    this.deleteModal = false;
                    this.deleteInfo = {};
                    this.$noti("success", data.message);
                })
                .catch((error) => {
                    this.isDeleting = false;
                    if (error.response.status === 404 || error.response.status === 406 || error.response.status === 400) {
                        this.$noti("warning", error.response.data.message);
                    } else {
                        this.errors = error.response.data.errors;
                        this.$noti("error", "Something Went Wrong !");
                    }
                });
        },
    },
};
</script>
<style scoped>
</style>