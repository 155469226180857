<template>
  <main class="justify-center mx-auto w-full md:w-3/4 lg:w-5/6">
    <div class="mx-1 my-4" v-if="infoLoading">
      <content-placeholders :rounded="true" :centered="true">
        <content-placeholders-img/>
        <content-placeholders-heading/>
        <content-placeholders-heading/>
      </content-placeholders>
    </div>
    <div v-else>
      <div v-for="(pg, index) in page" class="flex flex-col w-full shadow-xl bg-base-100 rounded-box text-primary-content mt-3 px-2 py-2 md:px-4 md:py-4" :key="index">
      <h1 class="font-bold">{{ pg.name }}</h1>
      <div v-html="pg.content"></div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from "axios";
import {mapActions, mapGetters} from "vuex";

export default {
  created() {
    document.title = this.$route.meta.title + ' | ' + this.$sitename;
    this.getPage();
  },
  mounted() {
    this.$Progress.finish();
  },
  data() {
    return {
      url: this.$config.FILE_URL,
      infoLoading: false,
      page: [],
    }
  },
  methods: {
    getPage() {
      axios.get('/pages/' + this.$route.params.slug + '')
        .then(({ data }) => {
          this.page = data;
        })
        .catch((error) => {
          console.log('Something went wrong')
        })
    },
  }
}
</script>

<style scoped>

</style>